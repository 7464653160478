export const environment = {
  production: false,

  VatBackendUrl: 'https://new.momstjek.dk',

  openIdConnectConfig: {
    stsServer: 'https://identity.kmd.dk/adfs/.well-known/openid-configuration',
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: `${window.location.origin}/login`,
    clientId: '9ccfdd5b-766e-462b-b7bf-9e576f312140',
    scope: 'openid urn:vat-analysis-api.prod/user_impersonation',
    responseType: 'code',
    triggerAuthorizationResultEvent: false,
    autoUserinfo: false,
    silentRenew: true,
    silentRenewUrl: `${window.location.origin}/silent-renew.html`,
    renewTimeBeforeTokenExpiresInSeconds: 300,
    useRefreshToken: true,
    startCheckSession: true,
    silentRenewTimeoutInSeconds: 60,
    customParams: {
      domain_hint: {
        default: 'kmd-ad-prod',
        kmd: 'kmd-ad-prod',
        bdo: 'bdo-aad-prod',
        dima: 'dima-idp-prod',
        assens: 'assens-ad-prod',
        favrskov: 'favrskov-ad-prod',
        hjørring: 'hjoerring-ad-prod',
        norddjurs: 'norddjurs-ad-prod',
        odense: 'odense-aad-prod',
        sorø: 'soroe-ad-prod',
        stevns: 'stevns-ad-prod',
        vejle: 'vejle-ad-prod',
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
